.scrollAnim {
  width: 40px;
  height: 60px;
  border: 1px solid black;
  border-radius: 35%;
  position: relative;
  box-shadow: 0 5px 5px rgba(0, 0, 0, .5);
  background: #e8e8e8;
}

@media only screen and (max-width: 800px) {
  .scrollAnim {
    /* display: none; */
  }
}

.dot1 {
  width: 7px;
  height: 10px;
  background-color: #000;
  border-radius: 100%;
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translateX(-50%);

  animation-name: example;
  animation-iteration-count: infinite;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-direction: alternate;
}

@keyframes example {
  from {
    height: 15px;
    top: 10%;
  }

  to {
    height: 20px;
    top: 50%;
  }
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes example {
  from {
    height: 5px;
    top: 10%;
  }

  to {
    height: 20px;
    top: 80%;
  }
}