.Home {
  max-height: 100vh;
  height: 100vh;
  overflow: hidden;
  display: flex;
}

@media only screen and (max-width: 800px) {
  .Home {
    flex-direction: column;    
    max-height: none;
  }
}