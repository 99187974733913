.CVpanel {
  transition: font-size 0.7s, flex 0.7s, 0.2s;
  flex: 1;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  box-shadow: -1px 0px 400px 10px rgba(255, 255, 255, 0.2);
  background: #0a0a0a;
  color: whitesmoke;
  background-size: cover;
  background-repeat: repeat;
  background-position: center center;
}

.CVpanel p {
  padding: 5px 10px;
  /* border-left: 1px solid rgba(255, 255, 255, 0.12); */
  line-height: 1.5rem;
}

.flex1 {
  flex: 1;
  min-width: 250px;
}

.biggerFlex {
  flex: 2;
  min-width: 100%;
}

.overScroll {
  height: 100%;
  overflow: scroll;
  justify-content: center;
}

.open {
  flex: 5;
  display: flex;
  justify-content: center;
  font-size: 16px;
  flex-wrap: wrap;
}

.pl15 {
  padding: 15px;
  border-left: 1px solid #8080804d;
  padding-right: 100px;
}

.textJustify {
  text-align: justify;
}

.social {
  display: flex;
  justify-content: center;
  align-items: center;
}

.socialIcon {
  width: 30px;
  height: 30px;
  overflow: hidden;
  margin: 5px;
}

.socialIcon img {
  width: 100%;
  height: auto;
}

.scrollIconHolder {
  position: absolute;
  bottom: 10px;
  right: 20px;
}

@media only screen and (max-width: 800px) {
  .scrollIconHolder{
    display: none;
  }  
}

.badgesHolder {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.customBtn {
  padding: 5px 20px;
  border: 1px solid #fff;
  letter-spacing: 2px;
  height: 40px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s;
  outline: none;
  text-decoration: none;
  color: white;
  background: transparent;
}

.customBtn:hover {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 320px) {
  .customBtn{
    padding: 5px;
  }  
}

.customLink {
  text-decoration: none;
  color: #5bc77c;
  font-weight: bold;
  transition: all 0.2s;
}

.customLink:hover {
  color: #43965c;
}

.divider {
  width: 90%;
  height: 1px;
  background: rgb(212, 212, 212);
  background: linear-gradient(
    8deg,
    rgba(212, 212, 212, 1) 0%,
    rgba(226, 226, 226, 1) 50%,
    rgba(232, 232, 232, 1) 100%
  );
}

@media only screen and (max-width: 360px) {
  .CVpanel {
    font-size: 16px;
  }
}

@media only screen and (max-width: 320px) {
  .CVpanel {
    font-size: 14px;
  }
}