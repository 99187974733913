@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
html, body {
  height: 100%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Layout {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    background: black;
}
  
.Layout__container {
flex: 1 1;
}
.Home {
  max-height: 100vh;
  height: 100vh;
  overflow: hidden;
  display: flex;
}

@media only screen and (max-width: 800px) {
  .Home {
    flex-direction: column;    
    max-height: none;
  }
}
.CVpanel {
  transition: font-size 0.7s, flex 0.7s, 0.2s;
  flex: 1 1;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  box-shadow: -1px 0px 400px 10px rgba(255, 255, 255, 0.2);
  background: #0a0a0a;
  color: whitesmoke;
  background-size: cover;
  background-repeat: repeat;
  background-position: center center;
}

.CVpanel p {
  padding: 5px 10px;
  /* border-left: 1px solid rgba(255, 255, 255, 0.12); */
  line-height: 1.5rem;
}

.flex1 {
  flex: 1 1;
  min-width: 250px;
}

.biggerFlex {
  flex: 2 1;
  min-width: 100%;
}

.overScroll {
  height: 100%;
  overflow: scroll;
  justify-content: center;
}

.open {
  flex: 5 1;
  display: flex;
  justify-content: center;
  font-size: 16px;
  flex-wrap: wrap;
}

.pl15 {
  padding: 15px;
  border-left: 1px solid #8080804d;
  padding-right: 100px;
}

.textJustify {
  text-align: justify;
}

.social {
  display: flex;
  justify-content: center;
  align-items: center;
}

.socialIcon {
  width: 30px;
  height: 30px;
  overflow: hidden;
  margin: 5px;
}

.socialIcon img {
  width: 100%;
  height: auto;
}

.scrollIconHolder {
  position: absolute;
  bottom: 10px;
  right: 20px;
}

@media only screen and (max-width: 800px) {
  .scrollIconHolder{
    display: none;
  }  
}

.badgesHolder {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.customBtn {
  padding: 5px 20px;
  border: 1px solid #fff;
  letter-spacing: 2px;
  height: 40px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s;
  outline: none;
  text-decoration: none;
  color: white;
  background: transparent;
}

.customBtn:hover {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 320px) {
  .customBtn{
    padding: 5px;
  }  
}

.customLink {
  text-decoration: none;
  color: #5bc77c;
  font-weight: bold;
  transition: all 0.2s;
}

.customLink:hover {
  color: #43965c;
}

.divider {
  width: 90%;
  height: 1px;
  background: rgb(212, 212, 212);
  background: linear-gradient(
    8deg,
    rgba(212, 212, 212, 1) 0%,
    rgba(226, 226, 226, 1) 50%,
    rgba(232, 232, 232, 1) 100%
  );
}

@media only screen and (max-width: 360px) {
  .CVpanel {
    font-size: 16px;
  }
}

@media only screen and (max-width: 320px) {
  .CVpanel {
    font-size: 14px;
  }
}
.scrollAnim {
  width: 40px;
  height: 60px;
  border: 1px solid black;
  border-radius: 35%;
  position: relative;
  box-shadow: 0 5px 5px rgba(0, 0, 0, .5);
  background: #e8e8e8;
}

@media only screen and (max-width: 800px) {
  .scrollAnim {
    /* display: none; */
  }
}

.dot1 {
  width: 7px;
  height: 10px;
  background-color: #000;
  border-radius: 100%;
  position: absolute;
  top: 90%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);

  -webkit-animation-name: example;

          animation-name: example;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}

@keyframes example {
  from {
    height: 15px;
    top: 10%;
  }

  to {
    height: 20px;
    top: 50%;
  }
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes example {
  from {
    height: 5px;
    top: 10%;
  }

  to {
    height: 20px;
    top: 80%;
  }
}
html {
  box-sizing: border-box;
  font-family: 'helvetica neue';
  font-size: 20px;
  font-weight: 200;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

iframe {
  width: 100%;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.Portfolio {
  color: white;
  width: 100%;
  max-width: 1500px;
  display: block;
  padding: 10px 15px;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media (max-width: 600px) {
  .Portfolio {
    margin: 0px auto;
    padding-bottom: 50px;
  }
}

.Portfolio__list {
  padding: 0;
}

.Portfolio__listItem {
  list-style-type: none;
  border: 1px solid #eee;
  cursor: pointer;
  padding: 10px;
  transition: all 0.2s;
}

.Portfolio__listItem:hover {
  box-shadow: 0px 2px 3px #ccc;
}

.Portfolio__info {
  border: 1px solid red;
  padding: 20px 10px;
}

.Card {
  background-color: black;
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin: 15px;
  width: 50%;
  max-width: 400px;
  height: 300px;
  padding: 15px;
  transition: all 0.5s;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
}

.Card::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 15px;
  opacity: 0;
  transition: all 0.2s;
  font-size: 14px;
  line-height: 1.5rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CardTund {
  background-image: url(/static/media/tun.4142606b.png);
}

.CardTund::after {
  content: "theTUNDRA is a multimedia Enthusiast network serving more than 70 special interest communities and nearly 500 community groups. Launched in February 2020, the interactive platform offers members an unparalleled personalized experience with curated Newsfeeds, Podcasts, Video, Groups, Discussion Forums, Virtual Conventions, Marketplace and more.";
}

.CardTundVir {
  background-image: url(/static/media/tv1.02c3d237.png);
}

.CardTundVir::after {
  content: "Designed to keep your event audience engaged year-round, TUNDRAVirtual provides event planners a completely customizable event experience, offering agile tech supporting events that range from one hour to one week, reaching diverse audiences from small groups to large conventions of 10,000 or more.";
}

.CardEx {
  background-image: url(/static/media/ex.25004924.png);
}

.CardEx::after {
  content: "One of the most popular names among crypto currency services is Excoino. Excoino's android application and website are huge platforms that provide online crypto currency wallets for users and also a good source of crypto news.";
}

.noOverlay {
  @media (min-width: 1050px) {
    .Card.noOverlay:hover {
      border: 4px solid white;
    }
  }
}

.codepenHolder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.codepenHolder svg {
  width: 50%;
}

.codepenHolder span {
  margin-top: 15px;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
  text-align: center;
}

.Card:not(.noOverlay) {
  @media (min-width: 1050px) {
    .Card:not(.noOverlay):hover::after {
      opacity: 1;
    }
  }
}

@media (max-width: 1050px) {
  .Card {
    width: 300px;
    height: 300px;
  }

  .Card::after {
    display: none;
  }
}

@media (max-width: 600px) {
  .Card {
    width: 200px;
    height: 200px;
  }
}

.headline {
  color: white;
  text-align: center;
  width: 50%;
  margin: 50px auto 0;
  border-bottom: 1px solid;
  padding-bottom: 10px;
  letter-spacing: 10px;
}

@media (max-width: 1050px) {
  .headline {
    font-size: 25px;
  }
}

@media (max-width: 600px) {
  .headline {
    font-size: 20px;
    margin: 70px auto 0;
    width: 80%;
  }
}
html {
  box-sizing: border-box;
  font-family: 'helvetica neue';
  font-size: 20px;
  font-weight: 200;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

iframe {
  width: 100%;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.blob {
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  margin: 10px;
  height: 20px;
  width: 20px;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-animation: pulse-black 2s infinite;
          animation: pulse-black 2s infinite;
}

@-webkit-keyframes pulse-black {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes pulse-black {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
