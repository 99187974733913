@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

html {
  box-sizing: border-box;
  font-family: 'helvetica neue';
  font-size: 20px;
  font-weight: 200;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

iframe {
  width: 100%;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.Portfolio {
  color: white;
  width: 100%;
  max-width: 1500px;
  display: block;
  padding: 10px 15px;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media (max-width: 600px) {
  .Portfolio {
    margin: 0px auto;
    padding-bottom: 50px;
  }
}

.Portfolio__list {
  padding: 0;
}

.Portfolio__listItem {
  list-style-type: none;
  border: 1px solid #eee;
  cursor: pointer;
  padding: 10px;
  transition: all 0.2s;
}

.Portfolio__listItem:hover {
  box-shadow: 0px 2px 3px #ccc;
}

.Portfolio__info {
  border: 1px solid red;
  padding: 20px 10px;
}

.Card {
  background-color: black;
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin: 15px;
  width: 50%;
  max-width: 400px;
  height: 300px;
  padding: 15px;
  transition: all 0.5s;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
}

.Card::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 15px;
  opacity: 0;
  transition: all 0.2s;
  font-size: 14px;
  line-height: 1.5rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CardTund {
  background-image: url('../../assets/tun.png');
}

.CardTund::after {
  content: "theTUNDRA is a multimedia Enthusiast network serving more than 70 special interest communities and nearly 500 community groups. Launched in February 2020, the interactive platform offers members an unparalleled personalized experience with curated Newsfeeds, Podcasts, Video, Groups, Discussion Forums, Virtual Conventions, Marketplace and more.";
}

.CardTundVir {
  background-image: url('../../assets/tv1.png');
}

.CardTundVir::after {
  content: "Designed to keep your event audience engaged year-round, TUNDRAVirtual provides event planners a completely customizable event experience, offering agile tech supporting events that range from one hour to one week, reaching diverse audiences from small groups to large conventions of 10,000 or more.";
}

.CardEx {
  background-image: url('../../assets/ex.png');
}

.CardEx::after {
  content: "One of the most popular names among crypto currency services is Excoino. Excoino's android application and website are huge platforms that provide online crypto currency wallets for users and also a good source of crypto news.";
}

.noOverlay {
  @media (min-width: 1050px) {
    .Card.noOverlay:hover {
      border: 4px solid white;
    }
  }
}

.codepenHolder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.codepenHolder svg {
  width: 50%;
}

.codepenHolder span {
  margin-top: 15px;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
  text-align: center;
}

.Card:not(.noOverlay) {
  @media (min-width: 1050px) {
    .Card:not(.noOverlay):hover::after {
      opacity: 1;
    }
  }
}

@media (max-width: 1050px) {
  .Card {
    width: 300px;
    height: 300px;
  }

  .Card::after {
    display: none;
  }
}

@media (max-width: 600px) {
  .Card {
    width: 200px;
    height: 200px;
  }
}

.headline {
  color: white;
  text-align: center;
  width: 50%;
  margin: 50px auto 0;
  border-bottom: 1px solid;
  padding-bottom: 10px;
  letter-spacing: 10px;
}

@media (max-width: 1050px) {
  .headline {
    font-size: 25px;
  }
}

@media (max-width: 600px) {
  .headline {
    font-size: 20px;
    margin: 70px auto 0;
    width: 80%;
  }
}